import { useState, useEffect, useContext } from 'react'
import {
  FlexGrid,
  Box,
  Feedback,
  ActivityIndicator,
  StackView,
  StoryCard as StoryCardAllium,
} from '@telus-uds/components-web'

import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'
import { getLatestArticlesFromJsonRss } from './helpers'
import StyledImage from './styles'

export type LatestArticlesBlockProps = {
  blogCategory: string
  displayDate: boolean
}

const LatestArticlesBlock = ({ blogCategory, displayDate }: LatestArticlesBlockProps) => {
  const { locale } = useContext(SiteBuilderContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [articles, setArticles] = useState([])
  const [colWidth, setColWidth] = useState({ md: 12, lg: 12 })

  useEffect(() => {
    void getLatestArticles()
  }, [])

  // Get the latest articles of a specific category from a jsonRss content type
  const getLatestArticles = async () => {
    setLoading(true)

    try {
      const fetchedArticles = await getLatestArticlesFromJsonRss(blogCategory, locale.language)

      if (fetchedArticles) {
        // Manage card width based on number of articles
        switch (fetchedArticles.length) {
          case 1:
            setColWidth({ md: 6, lg: 4 })
            break
          case 2:
            setColWidth({ md: 10, lg: 8 })
            break
          default:
            setColWidth({ md: 12, lg: 12 })
        }

        setArticles(fetchedArticles)
      }
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div data-testid="latest-articles-block-test-id">
      {error && (
        <Feedback validation="error" variant={{ icon: true }} title="Sorry">
          Something went wrong...
        </Feedback>
      )}
      {!error && (
        <>
          <FlexGrid>
            <Box space={3}>
              {loading && (
                <FlexGrid.Row horizontalAlign="center">
                  <FlexGrid.Col>
                    <ActivityIndicator label="fetching articles" />
                  </FlexGrid.Col>
                </FlexGrid.Row>
              )}
              {!loading && articles.length > 0 && (
                <FlexGrid.Row>
                  <FlexGrid.Col lg={colWidth.lg} md={colWidth.md}>
                    <StackView direction={{ xs: 'column', md: 'row' }} space={{ xs: 3, md: 3, lg: 5 }}>
                      {articles.map((item, index) => {
                        return (
                          <StoryCardAllium
                            key={`latest-articles-block-story-card-${index}`}
                            href={item.link}
                            tag="article"
                            date={displayDate && item.publishDate ? item.publishDate : ''}
                            title={item.title}
                            description=""
                            fullBleedContent={{
                              content: <StyledImage src={item.image} alt={item.title} />,
                            }}
                          />
                        )
                      })}
                    </StackView>
                  </FlexGrid.Col>
                </FlexGrid.Row>
              )}
            </Box>
          </FlexGrid>
        </>
      )}
    </div>
  )
}

export default LatestArticlesBlock
